import React from 'react';
import './Popup.css';

const Popup = ({ content, onClose }) => {
    return (
        <div className="popup-bg">
            <div className="popup-container">
                <div className="popup-content">
                    <span className="close-button" onClick={onClose}>X</span>
                    <div className="popup-body">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
