import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { base } from "wagmi/chains";

const projectId = "8ae6b609d1e99b8ed74dffa385b108c2";
const chains = [base];
const wagmiConfig = defaultWagmiConfig({
  projectId,
  chains,
  metadata: {
    name: "GMBVault",
  },
});

createWeb3Modal({
  chains,
  projectId,
  wagmiConfig,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </React.StrictMode>
);
