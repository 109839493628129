import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as BackgroundSVG } from '../assets/svg/main.svg';
import gsap from 'gsap';
import '../global.css';
import Interface from './Interface';
import Popup from './Popup';

const Layout = ({ children }) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const [pondPosition, setPondPosition] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const [accessVaultPosition, setAccessVaultPosition] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const [showInterface, setShowInterface] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState('');

    useEffect(() => {
        const svgElement = svgRef.current;
        const containerElement = containerRef.current;

        const vaultGroup = svgElement.getElementById('WHAT_IS_THE_VAULT');
        const workGroup = svgElement.getElementById('HOW_DOES_IT_WORK');
        const rewardsGroup = svgElement.getElementById('WHAT_ABOUT_REWARDS');
        const pondGroup = svgElement.getElementById('POND');
        const accessVaultGroup = svgElement.getElementById('ACCESS_VAULT');
        const doorGroup = svgElement.getElementById('DOOR');

        if (doorGroup) {
            gsap.to(doorGroup, {
                rotation: 360,
                transformOrigin: "center",
                repeat: 0,
                duration: 5,
                ease: "elastic"
            });
        }

        const groups = {
            vaultGroup: { 
                content: (
                    <>
                        <p>Well, it's a next-gen treasure chest based on <span className='text-yellow'>veTokenomics</span>!</p>
                        <p>→ Here's the scoop:</p>
                        <p>By locking up your <span className='text-red'>$GMB</span> for a certain period, you get shiny <span className='text-green'>$GMBee</span> tokens in return. The amount of <span className='text-blue'>$GMBee</span> you get is based on your share of the whole pool. The more you lock, the more you earn. On top of that, any rewards that land in the vault get divvied up amongst <span className='text-orange'>$GMBee</span> holders.</p>
                    </>
                )
            },
            workGroup: { 
                content: (
                    <>
                        <p>Lock your <span className='text-yellow'>$GMB</span> to get that sweet <span className='text-orange'>$GMBee</span> honey!</p>
                        <p>Here's the deal: Toss your <span className='text-blue'>$GMB</span> tokens into the vault for <span className='text-lime'>3 months</span>, and watch the rewards roll in. The more <span className='text-red'>$GMB</span> you lock, the more <span className='text-yellow'>goodies</span> you collect!</p>
                        <p>Once the 3 months are up, <span className='text-green'>boom</span>! Claim your tokens back PLUS any additional rewards!</p>
                    </>
                )
            },
            rewardsGroup: {
                content: (
                    <>
                        <p>Rewards will rain down in the form of <span className='text-lime'>$GMB</span>, <span className='text-blue'>$ETH</span>, and a mix of other shiny tokens.</p>
                        <p>These can come from the project's own revenue or from our awesome <span className='text-red'>GMBase partners</span>.</p>
                    </>
                )
            }
        };

        const handleClick = (group, content) => {
            if (group) {
                gsap.to(group, {
                    y: -20,
                    duration: 1,
                    repeat: -1,
                    yoyo: true,
                    ease: 'power1.inOut'
                });

                group.addEventListener('click', () => {
                    setShowPopup(true);
                    setPopupContent(content);
                });

                group.style.cursor = 'pointer';
                group.addEventListener('mouseover', () => {
                    group.style.cursor = 'pointer';
                });
                group.addEventListener('mouseout', () => {
                    group.style.cursor = 'default';
                });
            }
        };

        handleClick(vaultGroup, groups.vaultGroup.content);
        handleClick(workGroup, groups.workGroup.content);
        handleClick(rewardsGroup, groups.rewardsGroup.content);

        const bbox = pondGroup.getBBox();
        const svgRect = svgElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();

        const scaleX = svgRect.width / svgElement.viewBox.baseVal.width;
        const scaleY = svgRect.height / svgElement.viewBox.baseVal.height;

        setPondPosition({
            x: (bbox.x * scaleX) + svgRect.left - containerRect.left,
            y: (bbox.y * scaleY) + svgRect.top - containerRect.top,
            width: bbox.width * scaleX,
            height: bbox.height * scaleY,
        });

        const accessVaultBBox = accessVaultGroup.getBBox();

        setAccessVaultPosition({
            x: (accessVaultBBox.x * scaleX) + svgRect.left - containerRect.left,
            y: (accessVaultBBox.y * scaleY) + svgRect.top - containerRect.top,
            width: accessVaultBBox.width * scaleX,
            height: accessVaultBBox.height * scaleY,
        });

        pondGroup.style.cursor = 'pointer';
        pondGroup.addEventListener('mouseover', () => {
            pondGroup.style.cursor = 'pointer';
        });
        pondGroup.addEventListener('mouseout', () => {
            pondGroup.style.cursor = 'default';
        });

        accessVaultGroup.style.cursor = 'pointer';
        accessVaultGroup.addEventListener('mouseover', () => {
            accessVaultGroup.style.cursor = 'pointer';
        });
        accessVaultGroup.addEventListener('mouseout', () => {
            accessVaultGroup.style.cursor = 'default';
        });

    }, []);

    const handlePondClick = () => {
        window.location.href = 'https://gmb.finance';
    };

    const handleAccessVaultClick = () => {
        setShowInterface(true);
    };

    const handleCloseInterface = () => {
        setShowInterface(false); 
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupContent('');
    };

    return (
        <div className="layout-container" ref={containerRef}>
            <BackgroundSVG ref={svgRef} className="background-svg" />
            <div className="content">
                {children}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: `${pondPosition.x}px`,
                    top: `${pondPosition.y}px`,
                    width: `${pondPosition.width}px`,
                    height: `${pondPosition.height}px`,
                    cursor: 'pointer',
                    zIndex: 1,
                    backgroundColor: 'transparent', 
                }}
                onClick={handlePondClick}
            />
            <div
                style={{
                    position: 'absolute',
                    left: `${accessVaultPosition.x}px`,
                    top: `${accessVaultPosition.y}px`,
                    width: `${accessVaultPosition.width}px`,
                    height: `${accessVaultPosition.height}px`,
                    cursor: 'pointer',
                    zIndex: 1,
                    backgroundColor: 'transparent',
                }}
                onClick={handleAccessVaultClick}
            />
            {showInterface && <Interface onClose={handleCloseInterface} />}

            {showPopup && <Popup content={popupContent} onClose={handleClosePopup} />}
        </div>
    );
};

export default Layout;
