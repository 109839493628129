import React, { useRef, useEffect, useState } from 'react';
import './Interface.css';
import { ReactComponent as InterfaceSVG1 } from '../assets/svg/interface-1.svg';
import { ReactComponent as InterfaceSVG2 } from '../assets/svg/interface-2.svg';
import { ReactComponent as InterfaceSVG3 } from '../assets/svg/interface-3.svg';
import { ReactComponent as InterfaceSVG4 } from '../assets/svg/interface-4.svg';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useContractRead, useNetwork } from 'wagmi';
import { ethers, formatUnits, parseEther } from 'ethers';
import { VAULT_ABI } from '../abi/vaultAbi'
import { GMB_ABI } from '../abi/gmbAbi';


const Interface = ({ onClose }) => {
    const svgRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]; // Four refs for four interfaces
    const [currentIndex, setCurrentIndex] = useState(0);
    const [walletConnected, setWalletConnected] = useState(false);
    const [inputValues, setInputValues] = useState({
        lockAmount: '',
        extendAmount: ''
    });
    const interfaces = [InterfaceSVG1, InterfaceSVG2, InterfaceSVG3, InterfaceSVG4];
    const InterfaceComponent = interfaces[currentIndex];
    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount();
    const { chain } = useNetwork();
    const [provider, setProvider] = useState(null);
    const [vaultContract, setVaultContract] = useState(null);
    const [gmbContract, setGmbContract] = useState(null);
    const GMB_CONTRACT_ADDRESS = '0xC890EB927871660C7259F0DCAaF3d8a7CE5fA8C1';
    const VAULT_CONTRACT_ADDRESS = '0x5E64eE5c6A07332a0F6C0Cb7FC34A55798c80d55';

    const { data: gmbBalance, isError: isGmbError, isLoading: isGmbLoading } = useContractRead({
        address: GMB_CONTRACT_ADDRESS,
        abi: GMB_ABI,
        functionName: 'balanceOf',
        args: [address],
        enabled: isConnected && chain?.id === 8453,
    });

    const { data: totalLockedTokens, isError: istotalLockedTokensError, isLoading: istotalLockedTokensLoading } = useContractRead({
        address: VAULT_CONTRACT_ADDRESS,
        abi: VAULT_ABI,
        functionName: 'totalLockedTokens',
        enabled: isConnected && chain?.id === 8453,
    });

    const { data: userBalance, isError: isUserBalanceError, isLoading: isUserBalanceLoading } = useContractRead({
        address: VAULT_CONTRACT_ADDRESS,
        abi: VAULT_ABI,
        functionName: 'balanceOf',
        args: [address],
        enabled: isConnected && chain?.id === 8453,
    });

    const { data: userLockInfo, isError: isUserLockInfoError, isLoading: isUserLockInfoLoading } = useContractRead({
        address: VAULT_CONTRACT_ADDRESS,
        abi: VAULT_ABI,
        functionName: 'userLockInfo',
        args: [address],
        enabled: isConnected && chain?.id === 8453,
        select: (data) => {
            if (data) {
                const netAmount = data[0];
                return netAmount;
            }
            return null;
        },
    });

    const { data: usersCounter, isError: isUsersCounterError, isLoading: isUsersCounterLoading } = useContractRead({
        address: VAULT_CONTRACT_ADDRESS,
        abi: VAULT_ABI,
        functionName: 'usersCounter',
        enabled: isConnected && chain?.id === 8453,
    });

    useEffect(() => {
        const svgElement = svgRefs[currentIndex].current;
        const closeButtonId = `X_Button_${currentIndex + 1}`;
        const xButtonGroup = svgElement.getElementById(closeButtonId);
        const leftArrow = svgElement.getElementById('Arrow_Left');
        const rightArrow = svgElement.getElementById('Arrow_Right');
        const connectWalletButton = svgElement.getElementById('CONNECT_WALLET');
        const connectWalletText = svgElement.querySelector('#CONNECT_WALLET text');
        const balanceTextElement = svgElement.querySelector('#Balances_Text text:nth-child(4)');
        const addressTextElement = svgElement.querySelector('#Vault_Control_Text text:nth-child(2)');
        const gmbTextElement = svgElement.querySelector('#_0_GMB text');
        const totalLockedTokensElement = svgElement.querySelector('#Vault_Control_Text text:nth-child(7)');
        const userBalanceElement = svgElement.querySelector('#Vault_Control_Text text:nth-child(9)');
        const userBalanceElement2 = svgElement.querySelector('#Balances_Text text:nth-child(5)');
        const usersCounterElement = svgElement.querySelector('#Vault_Control_Text text:nth-child(8)');
        const claimElement = svgElement.getElementById('CLAIM');
        const lockElement = svgElement.getElementById('LOCK');
        const extendElement = svgElement.getElementById('EXTEND');

         // Hide left arrow on first interface
        if (leftArrow) {
            leftArrow.style.display = currentIndex === 0 ? 'none' : 'block';
        }

        // Hide right arrow on last interface
        if (rightArrow) {
            rightArrow.style.display = currentIndex === interfaces.length - 1 ? 'none' : 'block';
        }

        if (currentIndex === 3 && usersCounterElement) {
            usersCounterElement.innerHTML = walletConnected
                ? (isUsersCounterLoading ? 'Loading...' : (isUsersCounterError ? 'Error' : usersCounter))
                : '???????';
        }

        if (xButtonGroup) {
            xButtonGroup.addEventListener('click', handleCloseInterface);
            xButtonGroup.style.cursor = 'pointer';
        }

        if (leftArrow) {
            leftArrow.addEventListener('click', handleLeftArrowClick);
            leftArrow.style.cursor = 'pointer';
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', handleRightArrowClick);
            rightArrow.style.cursor = 'pointer';
        }

        if (connectWalletButton) {
            connectWalletButton.addEventListener('click', handleConnectWalletClick);
            connectWalletButton.style.cursor = 'pointer';
        }

        if (walletConnected && connectWalletText) {
            connectWalletText.innerHTML = '<tspan x="0" y="0">DISCONN.</tspan><tspan x="21.51" y="51.79">WALLET</tspan>';
        } else if (connectWalletText) {
            connectWalletText.innerHTML = '<tspan x="0" y="0">CONNECT</tspan><tspan x="21.51" y="51.79">WALLET</tspan>';
        }

        // Update the balance text field for all interfaces
        if (balanceTextElement) {
            balanceTextElement.innerHTML = walletConnected
                ? (isGmbLoading ? 'Loading...' : (isGmbError ? 'Error' : gmbBalance && Math.floor(Number(formatUnits(gmbBalance, 18)))))
                : '???????????';
        }
        // Update the LOCKED balance text field for all interfaces
        if (userBalanceElement2) {
            userBalanceElement2.innerHTML = walletConnected
                ? (isGmbLoading ? 'Loading...' : (isGmbError ? 'Error' : gmbBalance && Math.floor(Number(formatUnits(userLockInfo, 18)))))
                : '???????????';
        }

        if (currentIndex === 0 && addressTextElement) {
            addressTextElement.innerHTML = walletConnected
                ? '<tspan x="0" y="0">WALLET CONNECTED! VAULT UNLOCKED!</tspan><tspan x="0" y="38.4">USE THE GREEN ARROWS TO NAVIGATE</tspan>'
                : '<tspan x="0" y="0">CONNECT WALLET: ACCESS THE VAULT</tspan><tspan x="0" y="38.4">BY CONNECTING YOUR WALLET.</tspan>';
        }

        if (currentIndex === 1 && gmbTextElement) {
            gmbTextElement.innerHTML = walletConnected
                ? (isGmbLoading ? 'Loading...' : (isGmbError ? 'Error' : gmbBalance && Math.floor(Number(formatUnits(gmbBalance, 18))) + ' $GMB'))
                : '???????????';
        }

        if (currentIndex === 3 && totalLockedTokensElement) {
            totalLockedTokensElement.innerHTML = walletConnected
                ? (istotalLockedTokensLoading ? 'Loading...' : (istotalLockedTokensError ? 'Error' : Math.floor(Number(formatUnits(totalLockedTokens, 18)))))
                : '???????';
        }

        if (currentIndex === 3 && userBalanceElement) {
            userBalanceElement.innerHTML = walletConnected
                ? (isUserBalanceLoading ? 'Loading...' : (isUserBalanceError ? 'Error' : Math.floor(Number(formatUnits(userLockInfo, 18)))))
                : '???????';
        }

        // Input fields for locking and extension:
        if (currentIndex === 1 && gmbTextElement) {
            if (walletConnected) {
                const foreignObject = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
                foreignObject.setAttribute("x", "175");
                foreignObject.setAttribute("y", "750");
                foreignObject.setAttribute("width", "548");
                foreignObject.setAttribute("height", "55");
                const input = document.createElement("input");
                input.type = "text";
                input.value = inputValues.lockAmount;
                input.style.width = "100%";
                input.style.height = "100%";
                input.style.fontSize = "32px";
                input.style.fontFamily = "ChildChat";
                input.style.alignContent = "center";
                input.oninput = (e) => setInputValues({ ...inputValues, lockAmount: e.target.value });
                foreignObject.appendChild(input);
                gmbTextElement.parentNode.replaceChild(foreignObject, gmbTextElement);
            }
        }

        if (currentIndex === 2 && gmbTextElement) {
            if (walletConnected) {
                const foreignObject = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
                foreignObject.setAttribute("x", "175");
                foreignObject.setAttribute("y", "750");
                foreignObject.setAttribute("width", "548");
                foreignObject.setAttribute("height", "55");
                const input = document.createElement("input");
                input.type = "text";
                input.value = inputValues.extendAmount;
                input.style.width = "100%";
                input.style.height = "100%";
                input.style.fontSize = "32px";
                input.style.fontFamily = "ChildChat";
                input.style.alignContent = "center";
                input.oninput = (e) => setInputValues({ ...inputValues, extendAmount: e.target.value });
                foreignObject.appendChild(input);
                gmbTextElement.parentNode.replaceChild(foreignObject, gmbTextElement);
            }
        }

        const initializeContracts = async () => {
            if (isConnected && chain?.id === 8453) {
                try {
                    await window.ethereum?.request({ method: "eth_requestAccounts" });
                    const web3Provider = new ethers.BrowserProvider(window.ethereum);
                    const signer = await web3Provider.getSigner();
                    setProvider(web3Provider);
                    const vaultContractInstance = new ethers.Contract(VAULT_CONTRACT_ADDRESS, VAULT_ABI, signer);
                    const gmbContractInstance = new ethers.Contract(GMB_CONTRACT_ADDRESS, GMB_ABI, signer);
                    setVaultContract(vaultContractInstance);
                    setGmbContract(gmbContractInstance);
                } catch (error) {
                    console.error("Error initializing contracts:", error);
                }
            }
        };
        initializeContracts();

        if (claimElement) {
            claimElement.addEventListener('click', handleClaimTokens);
            claimElement.style.cursor = 'pointer';
        }

        if (lockElement) {
            lockElement.addEventListener('click', handleLockTokens);
            lockElement.style.cursor = 'pointer';
        }

        if (extendElement) {
            extendElement.addEventListener('click', handleExtendLock);
            extendElement.style.cursor = 'pointer';
        }

        return () => {
            if (xButtonGroup) xButtonGroup.removeEventListener('click', handleCloseInterface);
            if (leftArrow) leftArrow.removeEventListener('click', handleLeftArrowClick);
            if (rightArrow) rightArrow.removeEventListener('click', handleRightArrowClick);
            if (connectWalletButton) connectWalletButton.removeEventListener('click', handleConnectWalletClick);
            if (claimElement) claimElement.removeEventListener('click', handleClaimTokens);
            if (lockElement) lockElement.removeEventListener('click', handleLockTokens);
            if (extendElement) extendElement.removeEventListener('click', handleExtendLock);
        };
    }, [currentIndex, walletConnected, gmbBalance, totalLockedTokens, userBalance, userLockInfo, usersCounter, isConnected, chain, isGmbError, istotalLockedTokensError, isUserBalanceError, isUserLockInfoError, isUsersCounterError, isGmbLoading, istotalLockedTokensLoading, isUserBalanceLoading, isUserLockInfoLoading, isUsersCounterLoading, inputValues]
    );

    const checkGMBApproval = async () => {
        if (gmbContract && address) {
            try {
                const allowance = await gmbContract.allowance(address, VAULT_CONTRACT_ADDRESS);
                const amountToLockBigInt = parseEther(inputValues.lockAmount);
                const allowanceBN = BigInt(allowance);
                return allowanceBN >= (amountToLockBigInt);
            } catch (error) {
                console.error('Error checking GMB approval:', error);
                return false;
            }
        }
        return false;
    };

    const requestGMBApproval = async () => {
        if (gmbContract) {
            try {
                const tx = await gmbContract.approve(VAULT_CONTRACT_ADDRESS, ethers.MaxUint256);
                await tx.wait();
                console.log('GMB approval successful:', tx.hash);
            } catch (error) {
                console.error('Error requesting GMB approval:', error);
            }
        }
    };

    const handleClaimTokens = async () => {
        if (vaultContract) {
            try {
                const tx = await vaultContract.claimTokens();
                await tx.wait();
                console.log("Claim successful:", tx.hash);
            } catch (error) {
                console.error('Error claiming tokens:', error);
            }
        }
    };


    /* global BigInt */ // for some reason this needs to be in a comment so it doesnt give not-declared error
    const handleLockTokens = async () => {
        if (vaultContract) { 
            try {
                const isApproved = await checkGMBApproval();
                if (!isApproved) {
                    console.warn('GMB token approval required. Requesting approval...');
                    await requestGMBApproval();
                    return;
                }

                const amountToLockBigInt = parseEther(inputValues.lockAmount);
                const tx = await vaultContract.lockTokens(amountToLockBigInt); // Use vaultContract
                await tx.wait();
                console.log("Lock successful:", tx.hash);
                // Optionally, display a success message to the user
            } catch (error) {
                console.error('Error locking tokens:', error);
                // Handle specific errors here and provide feedback to the user
            }
        }
    };


    const handleExtendLock = async () => {
        if (vaultContract) {
            try {
                const isApproved = await checkGMBApproval();
                if (!isApproved) {
                    console.warn('GMB token approval required. Requesting approval...');
                    await requestGMBApproval();
                    return;
                }

                const amountToExtendBigInt = parseEther(inputValues.extendAmount);
                const tx = await vaultContract.extendLock(amountToExtendBigInt);
                await tx.wait();
                console.log("Extend successful:", tx.hash);
            } catch (error) {
                console.error('Error extending lock:', error);
            }
        }
    };



    const handleLeftArrowClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleRightArrowClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex < interfaces.length - 1 ? prevIndex + 1 : prevIndex));
    };

    const handleConnectWalletClick = async () => {
        try {
            if (walletConnected) {
                setWalletConnected(false);
            } else {
                await open();
                setWalletConnected(true);
            }
        } catch (error) {
            console.error('Error connecting wallet:', error);
        }
    };

    const handleCloseInterface = () => {
        onClose();
    };

    return (
        <div className="interface-container">
            <InterfaceComponent className="interface-svg" ref={svgRefs[currentIndex]} />
        </div>
    );
};

export default Interface;